:<template>
  <div>
  <el-dialog
          :append-to-body="true"
          v-if="dialog"
          :visible.sync="dialog"
          :title="isAudit ? '出金审核' : '出金信息'"
          width="800px"
          :before-close="cancel"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
          v-if="applyInfoShow"
          label="申请信息" name="first">
        <el-row>
          <el-col :span="12"><span class="label">申请单号:</span> <span>{{ data.transactionId }}</span></el-col>
            <el-col :span="12"><span class="label">申请时间: </span> <span>{{ parseTime(data.createTime) }}</span></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><span class="label">客户ID:</span> <span>{{ data.custId || '' }}</span></el-col>
          <el-col :span="8"><span class="label">邮箱: </span> <span>{{data.email}}</span></el-col>
          <el-col :span="8"><span class="label">电话号码: </span> <span>{{data.phone}}</span></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><span class="label">MT4帐号:</span> <span>{{ data.login}}</span></el-col>
          <el-col :span="8"><span class="label">MT4分组: </span> <span>{{data.group}}</span></el-col>
          <el-col :span="8"><span class="label">MT4杠杆: </span> <span>{{data.leverage}}</span></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"><span class="label">出金金额: </span><span>${{ data.amount }}(￥{{rmb}})</span></el-col>
          <el-col :span="8"><span class="label">汇率: </span><span>{{ data.nominalExRate }}</span></el-col>
          <el-col :span="8"><span class="label">审核状态:</span> <span>{{ parseWithdrawStatus(data.status) }}</span></el-col>
        </el-row>
        <div class="withdraw-info-apply">
            <el-row>
                <el-col><span  class="label">出金方式: </span><span>{{ getPayType() }}</span></el-col>
            </el-row>
            <el-row v-if="bankApplyInfoFlag">
                <el-col
                        :span="12"
                ><span class="label">银行名称:</span> <span>{{ backInfo && backInfo.bankName }}</span></el-col>
                <el-col
                        :span="12"
                ><span class="label">银行帐号: </span> <span>{{backInfo && backInfo.bankAccount }}</span></el-col>
            </el-row>
            <el-row v-if="bankApplyInfoFlag">
                <el-col :span="12"><span class="label">银行网点:</span> <span>{{ backInfo && backInfo.bankOutletProvince}}-{{ backInfo && backInfo.bankOutletCity}}-{{ backInfo && backInfo.bankOutlet}}</span></el-col>
                <el-col :span="12"><span class="label">SwiftCode:</span> <span>{{ backInfo && backInfo.swiftCode }}</span></el-col>
            </el-row>
            <el-row v-if="otcApplyInfoFlag" class="row-box">
                <el-col :span="14" class="label">
                    地址:
                    <span class="address-span">{{otcInfo && otcInfo.address}}</span>
                </el-col>
                <el-col class="img-col" :span="10">
                    <span class="label">二维码:</span>
                    <el-image
                            v-if="otcInfo.qrPic"
                            class="qr-code img-el"
                            :src="otcInfo.qrPic"
                            :preview-src-list="[otcInfo.qrPic]"
                    />
                </el-col>
            </el-row>
            <el-row v-if="usdtApplyInfoFlag">
                <el-col :span="14" class="label">
                    地址: <span class="address-span">{{usdtInfo && usdtInfo.address}}</span>
                </el-col>
                <el-col class="img-col" :span="10">
                    <span class="label">二维码:</span>
                    <el-image
                            v-if="usdtInfo.qrPic"
                            class="qr-code img-el"
                            :src="usdtInfo.qrPic"
                            :preview-src-list="[usdtInfo.qrPic]"
                    />
                </el-col>
            </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane
          v-if="applyerInfoShow"
          label="申请人信息"
          name="second"
          v-loading="loadingUser">
          <el-row>
            <el-col :span="8"><span class="label">用户名:</span> <span>{{ useInfo.userName ||' ' }}</span></el-col>
            <el-col :span="8"><span class="label">国籍: </span> <span>{{ useInfo.nationName}}</span></el-col>
            <el-col :span="8"><span class="label">生日: </span> <span>{{useInfo.birthday}}</span></el-col>
          </el-row>
          <el-row>
            <el-col :span="8"><span class="label">电话号码: </span> <span>{{ useInfo.phone}}</span></el-col>
            <el-col :span="8"><span class="label">邮箱:</span> <span>{{ useInfo.email }}</span></el-col>
          </el-row>
          <el-row>
            <el-col><span class="label">家庭住址:</span> <span>{{useInfo.homeProvince }}-{{useInfo.homeCity }}-{{useInfo.homeStreet }}-{{useInfo.homeAddress }}</span></el-col>
          </el-row>
      </el-tab-pane>
      <el-tab-pane
          v-if="auditLogShow"
          label="审核记录"
          name="thirdly"
      >
        <audit-log-table
          :tabel-data="events"
          @preview="previewImg"
        ></audit-log-table>
      </el-tab-pane>
    </el-tabs>
    <div v-if="isAudit" slot="footer" class="dialog-footer">
        <money-audit
            :style="{visibility: isWithdrawOperate ? 'hidden': 'visible'}"
            ref="moneyaudit"></money-audit>
      <el-row v-if="tableStatus===5 || isWithdrawOperate">
          <span class="label">支付渠道:</span>
          <el-radio-group v-model="withdrawType" style="padding-right:20px;">
            <el-radio :label="1">手动出金</el-radio>
            <el-radio :label="2">自动出金</el-radio>
          </el-radio-group>
          <el-upload v-if="withdrawType==1"
            class="upload"
                     ref="uploadcomponent"
            action="''"
            :http-request="handleSuccess"
            :before-upload="beforeUpload"
            :file-list="fileList"
            :on-preview="handlePreview"
            :limit=1
            list-type="picture"
          >
<!--            :on-success="handleSuccess"-->
            <el-button size="small" type="primary">点击上传转账凭证</el-button>
            <div slot="tip" class="el-upload__tip"><uploadImgTips></uploadImgTips></div>
          </el-upload>
          <el-select
            v-else
            v-model="withdrawChannel"
            clearable
            placeholder="支付渠道"
            class="filter-item"
            style="width: 130px"
          >
            <el-option
              v-for="item in withdrawChannelTypes"
              :key="item.channelCode"
              :label="item.channelName"
              :value="item.channelCode"
            />
          </el-select>
      </el-row>
      <el-button
        type="text"
        @click="cancel"
      >取消</el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="doSubmit"
      >通过</el-button>
    </div>
  </el-dialog>
  <el-dialog
    :append-to-body="true"
    :visible.sync="imgUrlShow"
    width="800px"
  >
    <img :src="imgUrl" width="100%">
  </el-dialog>
  </div>
</template>

<script>
  import moneyAudit from '@/components/public/moneyAudit'
  import auditLogTable from '@/components/public/auditLogTable'
  import withdrawInfo from '@/components/public/withdrawInfo'
import { audit,tPayFeeConfig} from '@/api/cus/tCustDepositWithdraw';
  import { uploadFile } from '@/api/data'
  import { getLimitUploadImageFormat,limitImageSizeFormat,getBase64, base64toFile,gePayTypeCommon,parseTime } from '@/utils/index'
import checkPermission from '@/utils/permission'
import { parseWithdrawStatus,parseOutType } from '@/views/pt/ib/utils/index'
import { initData } from '@/api/data'

export default {
  components:{
    withdrawInfo,
    auditLogTable,
    moneyAudit,
  },
  props: {
     tableStatus: {
      type: Number,
      required: true
    },
    isAudit: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    refresh: {
      type: Boolean,
      default: false
    },
    isWithdrawOperate:{
       type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      withdrawType:1,
      withdrawChannel:'',
      withdrawChannelTypes:[],
      fileList:[],
      proofImg: '',
      imgUrl:'',
      loadingUser:false,
      imgUrlShow: false,
      loading: false, dialog: false ,
      events: null,
      useInfo:{},
      imgUrls:[],
      activeName: checkPermission(['ADMIN','WITHDRAW_APPLY_INFO']) ? 'first' : checkPermission(['ADMIN','WITHDRAW_CUST_INFO']) ? 'second' : 'thirdly',
    }
  },
  watch:{
    data:{
      handler(newValue,oldValue){
        this.$nextTick(() => {
          this.$refs.uploadcomponent && this.$refs.uploadcomponent.clearFiles();
        })
      },
      deep: true,
    },
    dialog(newValue){
      if((this.applyInfoShow == false && this.applyerInfoShow) && newValue){
        this.loadingUser = true;
        this.getApplyerInfo();
      }
    }
  },
  computed: {
    applyInfoShow(){
      return checkPermission(['ADMIN','WITHDRAW_APPLY_INFO']);
    },
    applyerInfoShow(){
      return checkPermission(['ADMIN','WITHDRAW_CUST_INFO'])
    },
    auditLogShow(){
      return checkPermission(['ADMIN','WITHDRAW_AUDIT_INFO']);
    },
    backInfo(){
      if(this.data){
        if(this.data.snapshotList){
          for (let i = 0; i < this.data.snapshotList.length; i++) {
            if(this.data.snapshotList[i].payTypeId == 4){
              return this.data.snapshotList[i].custPayDetail;
              break;
            }
          }
        }
      }else {
        return {
          bankAccount: '',
          bankName: '',
          bankOutlet: '',
          swiftCode: '',
          bankOutletProvince: '',
          bankOutletCity: '',
        }
      }
    },
    usdtInfo(){
      if(this.data){
        if(this.data.snapshotList){
          for (let i = 0; i < this.data.snapshotList.length; i++) {
            if(this.data.snapshotList[i].payTypeId == 5){
              return this.data.snapshotList[i].custPayDetail;
              break;
            }
          }
        }
      }else {
        return {
          address: '',
          qrPic: '',
        }
      }
    },
    otcInfo(){
      if(this.data){
        if(this.data.snapshotList){
          for (let i = 0; i < this.data.snapshotList.length; i++) {
            if(this.data.snapshotList[i].payTypeId == 6){
              return this.data.snapshotList[i].custPayDetail;
              break;
            }
          }
        }
      }else {
        return {
          address: '',
          qrPic: '',
        }
      }
    },
    bankApplyInfoFlag(){
      return this.data.payTypeId == 4;
    },
    otcApplyInfoFlag(){
      return this.data.payTypeId == 6;
    },
    usdtApplyInfoFlag(){
      return this.data.payTypeId == 5;
    },
    rmb(){
      return (this.data.amount * this.data.nominalExRate).toFixed(2);
    }
  },
  methods: {
    parseTime,
    checkPermission,
    parseOutType,
    parseWithdrawStatus,
    beforeUpload(file) {
      return limitImageSizeFormat(file,this);
    },
    cancel(){
      this.dialog=false;
      this.loadingUser=false;
      this.useInfo={};
      this.imgUrls=[];
      this.loading = false;
      this.dialog=false;
      this.activeName = this.applyInfoShow ? 'first': this.applyerInfoShow ? 'second': 'thirdly';
    },
    handleSuccess(item){
      getBase64(item.file).then(res => {
        let file = base64toFile(res);
        uploadFile(file).then((res) => {
          this.proofImg = res.data.content[0].url;
        });
      });
    },
    handleClick(tab, event) {
      if(tab.name == 'second' && this.useInfo.id == null) {
        if(this.applyInfoShow){
          this.loadingUser = true;
          this.getApplyerInfo();
        }
      }
    },
    getApplyerInfo(){
      var url1 = 'crm/tCustInfo'
      var params1 = { id : this.data.custId,page: 0, size: 10}
      initData(url1,params1).then(res=>{
        this.useInfo = res.content[0];
        this.loadingUser = false;
      }).catch(err => {
        this.loadingUser = false
        console.log(err.response.data.message)
      })
    },
    handlePreview(file) {
      console.log(file);
    },
    previewImg(imgUrl){
      this.imgUrl = imgUrl;
      this.imgUrlShow = true;
    },
    getPayFeeConfig(){
      const params = {
        oprType: 2,
        isAdmin:1,
        status:1,
        payType: this.data.payTypeId,
      };
      if(this.data.riskControlFlag == 1){
        params.riskControlFlag = 0;
      }
      tPayFeeConfig(params).then(res => {
        this.withdrawChannelTypes = res.content;
      }).catch(err => {
      })
    },
    doSubmit(status) {

      const params = {
        id: this.data.id,
        decision: this.$refs.moneyaudit.auditResult,
        auditBy:this.data.status,
      }
      if(this.$refs.moneyaudit.auditResult ==2){
        params.rejectReason = this.$refs.moneyaudit.rejectReason;
        if(this.$refs.moneyaudit.rejectReason == -1){
          params.rejectReason = this.$refs.moneyaudit.rejectReasonSelf;
        }
      }
      if(this.tableStatus==5 || this.isWithdrawOperate) {
        if(this.withdrawType==2&&(this.withdrawChannel==null ||this.withdrawChannel=='')){
          this.$notify({
            title: '请选择出金渠道',
            type: 'error',
            duration: 2500
          })
          return
        }
        params['payoutType'] = this.withdrawType
        if (this.withdrawType==1&&this.proofImg!=null && this.proofImg!='') { params['proofImg'] = this.proofImg }
        if (this.withdrawType==2&&this.withdrawChannel!=null && this.withdrawChannel!='') { params['payType'] = this.withdrawChannel }
      };

      this.loading = true;
      audit(params).then(res => {
        this.$notify({
          title: '审核操作成功',
          type: 'success',
          duration: 2500
        })
        this.cancel()
        this.sup_this.init();
      }).catch(err => {
        this.loading = false;
        console.log(err.response.data.message)
      })
    },
    getPayType(){
      switch (this.data.payTypeId) {
        case 4:
          return '出金到银行卡';
        case 5:
          return '出金到USDT';
        case 6:
          return '出金到OTC365';
      }
    }
  }
}
</script>

<style scoped>
.el-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
  }
.el-col {
    border-radius: 4px;
  }
.label {
    font-size: 18px;
  }
</style>
<style lang="less" scoped>
    .withdraw-info-apply {
        background-color: #FAFAFA;
        .img-el {
            width: 100px;
            height: 100px;
        }
        .img-col {
            display: flex;
            align-items: center;
        }
    }
    .applyer-label {
        display: inline-block;
        font-size: 18px;
        width: 100px;
    }
</style>
